<template>
  <div>
    <div class="modalPopup" id="process_search_modal">
      <div class="modal_header">
        <h3 class="title">설비 검색</h3>
        <button class="modal_close" @click="$emit('onclose')"></button>
      </div>
      <div class="modal_body">
        <div class="modal_search_opt">
          <div class="input_search">
            <input
              type="text"
              :placeholder="`연동설비 검색`"
              :value="search_value"
              @input="typing"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="mes_tbl_wrap">
          <table class="mes_tbl">
            <thead>
              <tr>
                <th>점검기기명</th>
                <th>점검기기 위치</th>
                <th>점검항목</th>
                <th>합격기준</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(machine, index) in filtered_machine"
                :key="machine.id"
                @click="selectedIndex = index"
                @dblclick="submit()"
                :class="selectedIndex == index ? 'active' : ''"
              >
                <td>{{ getMachineName(machine.id) }}</td>
                <td>{{ machine.machine_location }}</td>
                <td>{{ machine.check_items }}</td>
                <td>{{ machine.pass_criteria }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal_footer">
        <div class="warning" v-show="error_text != ''">
          {{ error_text }}
        </div>
        <button type="button" class="btn_sub2" @click="submit()">
          선택
        </button>
      </div>
    </div>
    <div class="modal_layer" @click="$emit('onclose')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin, DRAG_MODAL_MIXIN],
  data() {
    return {
      error_text: '',
      selectedIndex: -1,
      search_value: '',
    };
  },
  props: {
    ignore_list: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      machineList: 'getMachineListFromMachinePage',
    }),
    filtered_machine() {
      const Hangul = require('hangul-js');
      let filtered_arr = this.machineList.filter(
        x =>
          x.name.includes(this.search_value) ||
          Hangul.d(x.name, true)
            .map(x => x[0])
            .join('')
            .includes(this.search_value),
      );
      let del_indexs = [];
      if (this.ignore_list != undefined && this.ignore_list.length > 0) {
        filtered_arr.forEach((el, index) => {
          this.ignore_list.forEach(el2 => {
            if (el.id == el2.inspection_id) {
              del_indexs.push(index);
            }
          });
        });
        let toFixIndex = 0;
        if (del_indexs.length > 0) {
          del_indexs.forEach(element => {
            filtered_arr.splice(element - toFixIndex, 1);
            toFixIndex = toFixIndex + 1;
          });
        }
      }
      return filtered_arr;
    },
  },
  methods: {
    typing(e) {
      this.search_value = e.target.value.trim();
      this.selectedIndex = -1;
    },
    submit() {
      if (this.selectedIndex == undefined || this.selectedIndex < 0) {
        this.error_text = `테이블의 행을 클릭하여 공정을 선택하여주십시오.`;
        return;
      }
      this.$emit('onselect', this.filtered_machine[this.selectedIndex]);
    },
    getMachineName(id) {
      const value = this.machineList.find(x => x.id == id);
      return value != undefined ? value.name : '';
    },
  },
  async created() {
    if (this.machineList.length < 1) {
      await this.FETCH('FETCH_MACHINE', '설비');
    }
  },
};
</script>

<style></style>
