<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>수리기간</span>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="startDate"
              @change="setStartDate($event)"
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="endDate"
              @change="setEndDate($event)"
            />
          </div>
          <span>점검기기</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'machine_name_selectric'"
              :watch="search_type"
              :options="machine_options_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
          <span>담당자</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'user_selectric'"
              :watch="user_search_type"
              :options="user_option_all"
              @changeValue="changeUserSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ repairHistory.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>설비 명</th>
              <th>수리기기 위치</th>
              <th>수리일</th>
              <th>수리항목</th>
              <th>수리 후 점검내용</th>
              <th>담당자</th>
              <th>수리이미지</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(history, index) in repairHistory"
              :key="(history.id, index)"
            >
              <td>
                {{ history.name
                }}<button
                  @click="selectedRow(index)"
                  type="button"
                  class="tbl_search"
                  v-show="managementMode"
                ></button>
              </td>
              <td>{{ history.machine_location }}</td>
              <td>
                <div v-show="!managementMode">{{ history.repair_date }}</div>
                <input
                  v-show="managementMode"
                  type="date"
                  v-model="history.repair_date"
                  placeholder="YYYY-MM-DD"
                />
              </td>
              <td>{{ history.check_items }}</td>
              <td v-show="!managementMode">{{ history.detail }}</td>
              <td v-show="managementMode">
                <input v-model="history.detail" type="text" />
              </td>
              <td>{{ getAccount(history.user_id) }}</td>
              <td>
                <button
                  class="btn_tbl"
                  @click="
                    () => {
                      managementSelectedIndex = index;
                      showImageModal = true;
                    }
                  "
                >
                  {{ history.image != null ? '수정' : '등록' }}
                </button>
              </td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal(history.id)">
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_wrap" v-show="managementMode">
        <button
          type="button"
          class="btn_sub2"
          :disabled="isValidModify"
          @click="save_data"
        >
          점검사항 저장
        </button>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_isp($event)"
    ></two-button-modal>
    <machine-check-search
      v-if="showMachineSearch"
      @onclose="showMachineSearch = false"
      @onselect="pushFilterCheck($event)"
    ></machine-check-search>
    <upload-image-modal
      v-if="showImageModal"
      :uploadedImage="repairHistory[managementSelectedIndex].image"
      :index="managementSelectedIndex"
      @onclose="showImageModal = false"
      @onselect="
        $event => {
          selectImage($event);
          showImageModal = false;
        }
      "
    ></upload-image-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MachineCheckSearch from '@/layouts/components/search-popup/MachineCheckSearch';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import UploadImageModal from '@/layouts/components/UploadImageModal';

export default {
  components: {
    MachineCheckSearch,
    TwoButtonModal,
    MyLocalSelectric,
    UploadImageModal,
  },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  data() {
    return {
      showSelectric: true,
      showMachineSearch: false,
      showImageModal: false,
      managementSelectedIndex: -1,
      userName: '',
      machineName: '',
      selectedRowNo: -1,
      changeList: [],
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMachineCheckPage',
      repairHistory: 'getRepairHistoryFromMachinePage',
      totalRepairHistory: 'getTotalRepairHistoryFromMachinePage',
      users: 'getUsersFromUserPage',
      startDate: 'getStartDateFromRepairHistoryPage',
      endDate: 'getEndDateFromRepairHistoryPage',
      machine_options_all: 'getMachinesForSelectricAll',
      search_type: 'getSearchTypeFromMachinePage',
      user_search_type: 'getUserSearchType',
      userList: 'getUsers',
      user_option_all: 'getUsersForSelectricAll',
    }),
    isValidModify() {
      if (this.managementMode) {
        let flag = false;
        for (let i = 0; i < this.changeList.length; i++) {
          if (
            JSON.stringify(this.changeList[i]) ===
            JSON.stringify(this.repairHistory[i])
          ) {
            flag = true;
          } else {
            flag = false;
            break;
          }
        }
        return flag;
      } else return false;
    },
  },
  methods: {
    selectImage(arg) {
      console.log(typeof arg.image);
      if (arg.image != 'null') {
        this.repairHistory[arg.index].image = arg.image;
      }
    },
    changeSelectric(type) {
      this.$store.commit('setSearchTypeToMachinePage', type);
      this.$store.commit('setRepairHistoryToMachinePage', {
        machine_id: type,
        user_id: this.user_search_type,
      });
    },
    changeUserSelectric(type) {
      this.$store.commit('setUserSearchType', type);
      this.$store.commit('setRepairHistoryToMachinePage', {
        machine_id: this.search_type,
        user_id: type,
      });
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    async delete_isp(index) {
      this.showSpinner();
      this.$store
        .dispatch('DELETE_REPAIR_HISTORY_DATA', index)
        .then(async () => {
          await this.$store.dispatch('FETCH_REPAIR_HISTORY_DATA', {
            start_date: this.startDate,
            end_date: this.endDate,
          });
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
          this.$store.commit(
            'setRepairHistoryToMachinePage',
            this.totalRepairHistory,
          );
        });

      this.hideSpinner();
    },
    pushFilterCheck(arg) {
      console.log(this.selectedRowNo[2]);
      let obj = this.repairHistory[this.selectedRowNo];
      obj.no = this.selectedRowNo + 1;
      obj.name = arg.name;
      obj.machine_location = arg.machine_location;
      obj.check_items = arg.check_items;
      obj.inspection_id = arg.id;
      if (arg == null) {
        return;
      }
      this.showMachineSearch = !this.showMachineSearch;
    },
    selectedRow(index) {
      this.showMachineSearch = !this.showMachineSearch;
      this.selectedRowNo = index;
    },
    getAccount(id) {
      return this.users.filter(user => user.id == id)[0].account;
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.endDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToRepairHistoryPage', e.target.value);
          await this.$store.dispatch('FETCH_REPAIR_HISTORY_DATA');
          this.$store.commit('setRepairHistoryToMachinePage', {
            machine_id: this.search_type,
            user_id: this.user_search_type,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToRepairHistoryPage',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.startDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToRepairHistoryPage', e.target.value);
          await this.$store.dispatch('FETCH_REPAIR_HISTORY_DATA');
          this.$store.commit('setRepairHistoryToMachinePage', {
            machine_id: this.search_type,
            user_id: this.user_search_type,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setEndDateToRepairHistoryPage',
          yyyymmdd(new Date()),
        );
      }
    },
    async save_data() {
      console.log(this.repairHistory);
      for (let i = 0; i < this.repairHistory.length; i++) {
        this.$store
          .dispatch('UPDATE_REPAIR_HISTORY_DATA', this.repairHistory[i])
          .then(async () => {
            this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
            if (this.managementMode) {
              !this.managementMode;
              this.$store.commit('toggleManagementModeToMachineCheckPage');
            }
            await this.$store.dispatch('FETCH_REPAIR_HISTORY_DATA');
          })
          .catch(error => {
            this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
            console.log(error);
          });
      }
    },
  },
  async created() {
    let date = new Date();
    if (this.startDate == '' && this.endDate == '') {
      date.setDate(date.getDate() + 1);
      this.$store.commit('setEndDateToRepairHistoryPage', yyyymmdd(date));
      date.setDate(date.getDate() - 13);
      this.$store.commit('setStartDateToRepairHistoryPage', yyyymmdd(date));
    }
    if (this.totalRepairHistory.length == 0) {
      await this.$store.dispatch('FETCH_REPAIR_HISTORY_DATA');
    }
    if (this.repairHistory.length == 0) {
      await this.$store.commit('setRepairHistoryToMachinePage', {
        machine_id: this.search_type,
        user_id: this.user_search_type,
      });
    }
    if (this.userList.length == 0) {
      this.$store.commit('setUsers', this.users);
    }
    this.changeList = this.lodash.clonedeep(this.repairHistory);
  },
};
</script>

<style></style>
