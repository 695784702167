<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>점검기기</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'machine_name_selectric'"
              :watch="search_type"
              :options="machine_options_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
          <span>담당자</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'user_selectric'"
              :watch="user_search_type"
              :options="user_option_all"
              @changeValue="changeUserSelectric($event)"
            >
            </my-local-selectric>
          </div>
          <span>조회 기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
          <button class="excel_btn" @click="exportExcel()">엑셀로 저장</button>
        </div>
      </div>
      <h6>조회수 : {{ filteredCheckResultList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>점검기기 명</th>
              <th>점검기기 위치</th>
              <th>점검일</th>
              <th>점검항목</th>
              <th>합격기준</th>
              <th>점검결과</th>
              <th>점검의견</th>
              <th>담당자</th>
              <th>점검이미지</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="machine in filteredCheckResultList" :key="machine.id">
              <td>
                {{ machine.name }}
              </td>
              <td>{{ machine.machine_location }}</td>
              <td>
                {{ machine.inspection_date }}
              </td>
              <td>{{ machine.check_items }}</td>
              <td>{{ machine.pass_criteria }}</td>
              <td>
                <div>{{ machine.inspection_result ? '통과' : '미흡' }}</div>
                <div></div>
              </td>
              <td>{{ machine.comments }}</td>
              <td>{{ getAccount(machine.user_id) }}</td>
              <td>
                <button
                  v-if="!machine.image.includes('null')"
                  class="check_btn"
                  @click="
                    () => {
                      imageTemp = machine.image;
                      showImageModal = true;
                    }
                  "
                >
                  조회
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <upload-image-modal
      v-if="showImageModal"
      :uploadedImage="imageTemp"
      :index="-1"
      @onclose="showImageModal = false"
      @onselect="showImageModal = false"
    ></upload-image-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import XLSX from 'xlsx';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import UploadImageModal from '@/layouts/components/UploadImageModal';
export default {
  components: { MyLocalSelectric, UploadImageModal },
  data() {
    return {
      imageTemp: null,
      showImageModal: false,
      showSelectric: false,
      checkResultTable: [],
    };
  },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMachineCheckPage',
      machine_options_all: 'getMachinesForSelectricAll',
      search_type: 'getSearchTypeFromMachinePage',
      user_search_type: 'getUserSearchType',
      machineList: 'getMachineListFromMachinePage',
      managementData: 'getManagementDataFromCompany',
      startDate: 'getStartDateFromCheckResultPage',
      endDate: 'getEndDateFromCheckResultPage',
      users: 'getUsersFromUserPage',
      userList: 'getUsers',
      user_option_all: 'getUsersForSelectricAll',
      checkResultList: 'getCheckResultListFromMachinePage',
      filteredCheckResultList: 'getFilteredCheckResultListFromMachinePage',
    }),
  },
  methods: {
    async changeSelectric(type) {
      await this.$store.commit('setSearchTypeToMachinePage', type);
      this.$store.commit('setFilteredCheckResultListToMachinePage', {
        machine_id: type,
        user_id: this.user_search_type,
      });
    },
    async changeUserSelectric(type) {
      await this.$store.commit('setUserSearchType', type);
      this.$store.commit('setFilteredCheckResultListToMachinePage', {
        machine_id: this.search_type,
        user_id: type,
      });
    },
    exportExcel() {
      let checkResult = [];

      const date = this.startDate + ' ~ ' + this.endDate;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];

      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];

      checkResult = checkResult.concat(standardInfo);

      checkResult.push([
        '점검기기명',
        '점검기기 위치',
        '점검일',
        '점검항목',
        '점검결과',
      ]);

      this.filteredCheckResultList.forEach(el => {
        checkResult.push([
          el.name,
          el.machine_location,
          el.inspection_date,
          el.check_items,
          el.inspection_result ? '통과' : '미흡',
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(checkResult, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '점검결과 목록');
      let title = '점검결과 목록(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
    async setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.endDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToCheckResultPage', e.target.value);
          await this.$store.dispatch('FETCH_CHECK_RESULT_DATA');
          await this.$store.commit('setFilteredCheckResultListToMachinePage', {
            machine_id: this.search_type,
            user_id: this.user_search_type,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStartDateToCheckResultPage',
          yyyymmdd(new Date()),
        );
      }
    },
    async setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.startDate}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToCheckResultPage', e.target.value);
          await this.$store.dispatch('FETCH_CHECK_RESULT_DATA');
          this.$store.commit('setFilteredCheckResultListToMachinePage', {
            machine_id: this.search_type,
            user_id: this.user_search_type,
          });
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToCheckResultPage', yyyymmdd(new Date()));
      }
    },
    getAccount(id) {
      return this.users.filter(user => user.id == id)[0].account;
    },
  },
  async created() {
    this.showSelectric = true;
    let date = new Date();
    if (this.startDate == '' && this.endDate == '') {
      date.setDate(date.getDate() + 1);
      this.$store.commit('setEndDateToCheckResultPage', yyyymmdd(date));
      date.setDate(date.getDate() - 13);
      this.$store.commit('setStartDateToCheckResultPage', yyyymmdd(date));
    }
    if (this.checkResultList.length == 0) {
      await this.$store.dispatch('FETCH_CHECK_RESULT_DATA');
      this.$store.commit('setFilteredCheckResultListToMachinePage', {
        machine_id: this.search_type,
        user_id: this.user_search_type,
      });
    }
    if (this.userList.length == 0) {
      this.$store.commit('setUsers', this.users);
    }
  },
};
</script>

<style></style>
