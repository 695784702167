<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>설비 명</th>
              <th>수리기기 위치</th>
              <th>수리일</th>
              <th>수리항목</th>
              <th>수리 후 점검내용</th>
              <th>담당자</th>
              <th>수리이미지</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(machine, index) in historyList"
              :key="(machine.no, index)"
            >
              <td>
                {{ machine.name
                }}<button
                  @click="selectedRow(index)"
                  type="button"
                  class="tbl_search"
                ></button>
              </td>
              <td>{{ machine.machine_location }}</td>
              <td>
                <input
                  v-model="machine.repair_date"
                  type="date"
                  placeholder="YYYY-MM-DD"
                />
              </td>
              <td>{{ machine.check_items }}</td>
              <td><input v-model="machine.detail" type="text" /></td>
              <td>{{ userId }}</td>
              <td>
                <button
                  class="btn_download"
                  @click="
                    () => {
                      selectedRowNo = machine.no;
                      showImageModal = true;
                    }
                  "
                >
                  {{ machine.image != 'null' ? '수정' : '등록' }}
                </button>
              </td>
              <td>
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>
            <tr class="newBtn new">
              <td>
                <button
                  @click="selectedRow(-1)"
                  type="button"
                  class="tbl_search2"
                ></button>
              </td>
              <td colspan="7"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_wrap">
        <button
          type="button"
          class="btn_sub2"
          @click="saveData"
          :disabled="this.historyList.length < 1"
        >
          점검사항 저장
        </button>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_isp($event)"
    ></two-button-modal>
    <upload-image-modal
      v-if="showImageModal"
      :uploadedImage="historyList[selectedRowNo - 1].image"
      :index="selectedRowNo - 1"
      @onclose="showImageModal = false"
      @onselect="
        $event => {
          selectImage($event);
          showImageModal = false;
        }
      "
    ></upload-image-modal>
    <machine-check-search
      v-if="showMachineSearch"
      :ignore_list="historyList"
      @onclose="showMachineSearch = false"
      @onselect="pushFilterCheck($event)"
    ></machine-check-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import MachineCheckSearch from '@/layouts/components/search-popup/MachineCheckSearch';
import FETCH_MIXIN from '@/mixins/fetch';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import UploadImageModal from '@/layouts/components/UploadImageModal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  components: { MachineCheckSearch, UploadImageModal, TwoButtonModal },
  mixins: [FETCH_MIXIN, ModalMixin, SpinnerMixin],
  data() {
    return {
      showMachineSearch: false,
      selectedRowNo: -1,
      showImageModal: false,
      resultSelectValue: null,
      resultSelect: [
        {
          label: '통과',
          value: 0,
        },
        {
          label: '미흡',
          value: 1,
        },
      ],
      // modal data
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      // table 데이터
      tableData: [],
      emptyData: {
        no: 1,
        name: '',
        machine_location: '',
        repair_date: yyyymmdd(new Date()),
        machine_id: '',
        check_items: '',
        inspection_id: '',
        detail: '',
        user_id: '',
        image: 'null',
      },
    };
  },
  computed: {
    ...mapGetters({
      machines: 'getMachines',
      historyList: 'getHistoryListFromMachinePage',
      userId: 'getUserId',
      users: 'getUsersFromUserPage',
      userInfo: 'getUserInfo',
      systemCompany: 'getSystemCompany',
    }),
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    setSelectricValue(e, index) {
      this.$store.commit('updateCheckListToMachinePage', {
        index: index,
        value: e,
      });
    },
    selectedRow(index) {
      this.showMachineSearch = !this.showMachineSearch;
      this.selectedRowNo = index;
    },
    pushFilterCheck(arg) {
      let newRow = this.lodash.clonedeep(this.emptyData);
      newRow.no = this.historyList.length + 1;
      newRow.user_id = this.users.filter(
        user => user.account == this.userId,
      )[0].id;
      newRow.repair_date = yyyymmdd(new Date());
      newRow.saup_no = this.systemCompany.saup_no;
      newRow.name = arg.name;
      newRow.machine_location = arg.machine_location;
      newRow.check_items = arg.check_items;
      newRow.inspection_id = arg.id;
      newRow.user_id = this.users.filter(
        user => user.account == this.userId,
      )[0].id;
      newRow.saup_no = this.systemCompany.saup_no;
      if (this.selectedRowNo == -1) {
        this.historyList.push(newRow);
      } else {
        this.historyList[this.selectedRowNo] = newRow;
      }
      if (arg == null) {
        return;
      }
      this.$store.commit('setHistoryListToMachinePage', this.historyList);
      this.showMachineSearch = !this.showMachineSearch;
    },
    delete_isp(index) {
      this.showSpinner();
      this.historyList.splice(index - 1, 1);
      this.CloseModal();
      this.hideSpinner();
    },
    selectImage(arg) {
      console.log(arg);
      if (arg.image != 'null') {
        this.historyList[arg.index].image = arg.image;
      }
    },
    saveData() {
      for (let i = 0; i < this.historyList.length; i++) {
        if (
          this.historyList[i].repair_date == '' ||
          this.historyList[i].detail == ''
        ) {
          this.openOneButtonModal(
            '저장 중 오류',
            '수리일, 수리 후 점검내용은 필수 입력항목입니다.',
          );
          return;
        }
        this.$store
          .dispatch('INSERT_REPAIR_HISTORY_DATA', this.historyList[i])
          .catch(error => {
            this.openOneButtonModal('저장 중 오류', '수리이력 저장중 오류');
            console.log(error);
            return;
          });
      }
      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
      this.$store.commit('setHistoryListToMachinePage', []);
    },
  },
};
</script>

<style></style>
