<template>
  <div
    id="contents"
    :class="{
      check_management: tabIndex == 0,
      check_result: tabIndex == 1 || tabIndex == 2,
      check_progress: tabIndex == 2,
      repair_history_management: tabIndex == 3,
      repair_history_enroll: tabIndex == 4,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToMachineCheckPage'"
          :options="asideLists"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && (tabIndex == 0 || tabIndex == 3)"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <CheckManagementForm v-if="tabIndex == 0" />
      <CheckResultForm v-if="tabIndex == 1" />
      <CheckProcessForm v-if="tabIndex == 2" />
      <RepairHistoryForm v-if="tabIndex == 3" />
      <RepairHistoryEnrollForm v-if="tabIndex == 4" />
    </div>
  </div>
</template>

<script>
import CheckManagementForm from '@/views/forms/Machine/MachineCheck/CheckManagementForm';
import CheckResultForm from '@/views/forms/Machine/MachineCheck/CheckResultForm';
import CheckProcessForm from '@/views/forms/Machine/MachineCheck/CheckProcessForm';
import RepairHistoryForm from '@/views/forms/Machine/MachineCheck/RepairHistoryForm';
import RepairHistoryEnrollForm from '@/views/forms/Machine/MachineCheck/RepairHistoryEnrollForm.vue';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  data() {
    return {
      asideLists: [
        { title: '점검기준 관리', name: 'check_management' },
        { title: '점검결과 조회', name: 'check_result' },
        { title: '점검 진행', name: 'check_progress' },
        { title: '수리이력 관리', name: 'repair_history_management' },
        { title: '수리이력 등록', name: 'repair_history_enrollment' },
      ],
    };
  },
  components: {
    AsideSelectric,
    CheckManagementForm,
    CheckResultForm,
    CheckProcessForm,
    RepairHistoryForm,
    RepairHistoryEnrollForm,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMachineCheckPage',
      tabIndex: 'getOpenTabIndexFromMachineCheckPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToMachineCheckPage',
      SetOpenTabIndex: 'setOpenTabIndexToMachineCheckPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitMachineCheckManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style></style>
