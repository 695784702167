<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>점검기기</span>
          <div>
            <my-local-selectric
              v-if="showSelectric"
              :id="'machine_name_selectric'"
              :watch="search_type"
              :options="machine_options_all"
              @changeValue="changeSelectric($event)"
            >
            </my-local-selectric>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filterdMachineList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl" :class="{ manage: managementMode }">
          <thead>
            <tr>
              <th>점검기기 명</th>
              <th>점검기기 위치</th>
              <th>점검주기</th>
              <th>점검항목</th>
              <th>합격기준</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(machine, index) in filterdMachineList"
              :key="(machine.id, index)"
            >
              <td>
                {{ machine.name
                }}<button
                  v-show="managementMode"
                  @click="selectedRow(machine)"
                  type="button"
                  class="tbl_search"
                ></button>
              </td>
              <td>{{ machine.machine_location }}</td>
              <td v-show="!managementMode">{{ machine.inspection_cycle }}</td>
              <td v-show="managementMode">
                <input v-model="machine.inspection_cycle" type="text" />
              </td>
              <td v-show="!managementMode">{{ machine.check_items }}</td>
              <td v-show="managementMode">
                <input v-model="machine.check_items" type="text" />
              </td>
              <td v-show="!managementMode">{{ machine.pass_criteria }}</td>
              <td v-show="managementMode">
                <input v-model="machine.pass_criteria" type="text" />
              </td>
              <td v-show="managementMode">
                <button
                  class="tbl_delete_btn"
                  @click="ShowModal(machine.id, index)"
                >
                  delete
                </button>
              </td>
            </tr>
            <tr v-show="managementMode" class="newBtn new">
              <td>
                <button
                  type="button"
                  class="tbl_search2"
                  @click="showMachineSearch = true"
                ></button>
              </td>
              <td colspan="5"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_wrap" v-show="managementMode">
        <button
          type="button"
          class="btn_sub2"
          @click="save_data"
          :disabled="isValidModify"
        >
          점검사항 저장
        </button>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_isp($event)"
    ></two-button-modal>
    <machine-search
      v-if="showMachineSearch"
      @onclose="showMachineSearch = false"
      @onselect="pushFilterCheck($event)"
    ></machine-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import MachineSearch from '@/layouts/components/search-popup/MachineSearch';

export default {
  components: { MyLocalSelectric, TwoButtonModal, MachineSearch },
  data() {
    return {
      showSelectric: false,
      showMachineSearch: false,
      selectedRowNo: -1,
      emptyData: {
        no: 1,
        machine_id: '',
        name: '',
        machine_location: '',
        check_items: '',
        inspection_cycle: '',
        pass_criteria: '',
        saup_no: '',
      },
      changeList: [],
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMachineCheckPage',
      machines: 'getMachines',
      machine_options_all: 'getMachinesForSelectricAll',
      search_type: 'getSearchTypeFromMachinePage',
      machineList: 'getMachineListFromMachinePage',
      filterdMachineList: 'getFilteredMachineListFromMachinePage',
    }),
    isValidModify() {
      if (this.managementMode) {
        if (this.changeList.length != this.filterdMachineList.length) {
          return false;
        }
        let flag = false;
        for (let i = 0; i < this.changeList.length; i++) {
          if (
            JSON.stringify(this.changeList[i]) ===
            JSON.stringify(this.filterdMachineList[i])
          ) {
            flag = true;
          } else {
            flag = false;
            break;
          }
        }
        return flag;
      } else return false;
    },
  },
  methods: {
    pushFilterCheck(arg) {
      console.log(JSON.stringify(arg));
      let newRow = this.lodash.clonedeep(this.emptyData);
      newRow.no = this.filterdMachineList.length + 1;
      newRow.name = arg.name;
      newRow.machine_location = arg.machine_location;
      newRow.machine_id = arg.id;
      if (arg == null) {
        return;
      }
      this.filterdMachineList.push(newRow);
      this.$store.commit(
        'setFilteredMachineListToMachinePage',
        this.filterdMachineList,
      );
      this.showMachineSearch = !this.showMachineSearch;
    },
    selectedRow(machine) {
      this.showMachineSearch = !this.showMachineSearch;
      this.selectedRowNo = machine.no;
    },
    ShowModal(id, index) {
      console.log(id + ' / ' + index);
      if (id == undefined) {
        this.filterdMachineList.splice(index, 1);
        return;
      }
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    changeSelectric(type) {
      this.$store.commit('setSearchTypeToMachinePage', type);
      this.SelectRow();
    },
    SelectRow() {
      if (this.search_type == null) {
        this.$store.commit(
          'setFilteredMachineListToMachinePage',
          this.machineList,
        );
      } else {
        this.$store.commit(
          'setFilteredMachineListToMachinePage',
          this.machineList.filter(
            machine => machine.machine_id == this.search_type,
          ),
        );
      }
    },
    async delete_isp(index) {
      this.showSpinner();
      if (index != null) {
        this.$store
          .dispatch(
            'DELETE_INSPECTION_MACHINE',
            this.filterdMachineList[index].id,
          )
          .then(async () => {
            await this.$store.dispatch(
              'FETCH_INSPECTION_MACHINE',
              this.machines,
            );
            this.openOneButtonModal('삭제 성공', '성공적으로 삭제하였습니다.');
            this.SelectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
          })
          .finally(async () => {
            this.hideSpinner();
            this.CloseModal();
          });

        this.hideSpinner();
      } else {
        return;
      }
    },
    async save_data() {
      let dispatch_method = '';
      for (let i = 0; i < this.filterdMachineList.length; i++) {
        dispatch_method =
          this.filterdMachineList[i].id != null
            ? 'UPDATE_INSPECTION_MACHINE'
            : 'INSERT_INSPECTION_MACHINE';
        console.log(dispatch_method);
        this.$store
          .dispatch(dispatch_method, this.filterdMachineList[i])
          .then(() => {
            this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
            if (this.managementMode) {
              !this.managementMode;
              this.$store.commit('toggleManagementModeToMachineCheckPage');
            }
            this.$store.dispatch('FETCH_INSPECTION_MACHINE', this.machines);
          })
          .catch(error => {
            this.openOneButtonModal('저장 중 오류', '주요 일정 저장중 오류');
            console.log(error);
          });
      }
    },
  },
  async created() {
    if (this.machines.length == 0) {
      await this.$store.dispatch('FETCH_MACHINE');
    }
    if (this.machineList.length == 0) {
      await this.$store.dispatch('FETCH_INSPECTION_MACHINE', this.machines);
    }
    await this.$store.commit('setSearchTypeToMachinePage', null);
    this.SelectRow();
    this.changeList = this.lodash.clonedeep(this.filterdMachineList);
    this.showSelectric = true;
  },
};
</script>

<style scoped>
tbody tr td {
  padding: 0px;
}
</style>
